@font-face {
  font-family: Favorit;
  src: url('./favorit-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Favorit;
  src: url('./Favorit-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Favorit;
  src: url('./FavoritStd-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}
